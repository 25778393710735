export const GET_INVOICED='GET_INVOICED'
export const GET_TOBE_INVOICE='GET_TOBE_INVOICE'
export const GET_DEALS='GET_DEALS'
export const SET_FILTER='SET_FILTER'
export const SET_TOTALVALUE_TOBE='SET_TOTALVALUE_TOBE'
export const SET_TOTALVALUE_INVOICED='SET_TOTALVALUE_INVOICED'
export const SET_DETAIL_INVOICED='SET_DETAIL_INVOICED'
export const SET_TAB_INVOICE='SET_TAB_INVOICE'
export const SET_INVOICE_ACTION='SET_INVOICE_ACTION'
export const TO_EXCEL='TO_EXCEL'
