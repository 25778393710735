export const GET_ACCOUNT_SUMMARY_REPORT='GET_ACCOUNT_SUMMARY_REPORT'
export const GET_ACCOUNT_REPORT_TABLE='GET_ACCOUNT_REPORT_TABLE'
export const SET_ACCOUNT_REPORT_FILTER='SET_ACCOUNT_REPORT_FILTER'
export const SET_ACCOUNT_TABLE_TITLE='SET_ACCOUNT_TABLE_TITLE'
export const GET_ACCOUNT_CHART='GET_ACCOUNT_CHART'
export const GET_ACCOUNT_REPORT_EXCEL='GET_ACCOUNT_REPORT_EXCEL'
export const GET_ACCOUNT_VISIT='GET_ACCOUNT_VISIT'
export const GET_ACCOUNT_PROPOSAL='GET_ACCOUNT_PROPOSAL'
export const SET_ACCOUNT_VISIT_PAGINATION='SET_ACCOUNT_VISIT_PAGINATION'
export const SET_ACCOUNT_PROPOSAL_PAGINATION='SET_ACCOUNT_PROPOSAL_PAGINATION'
export const SET_ACCOUNT_VISIT_SEARCH='SET_ACCOUNT_VISIT_SEARCH'
export const SET_ACCOUNT_PROPOSAL_SEARCH='SET_ACCOUNT_PROPOSAL_SEARCH'
export const SET_TARGET='SET_TARGET'
export const SET_TARGET_STATUS='SET_TARGET_STATUS'
export const SET_FILTER_SALES_VISIT='SET_FILTER_SALES_VISIT'
export const SET_FILTER_SALES_VISIT_MENTOR='SET_FILTER_SALES_VISIT_MENTOR'
export const SET_REPORT_LEADER='SET_REPORT_LEADER'
export const SET_REPORT_MENTOR='SET_REPORT_MENTOR'