export const GET_PIPELINE='GET_PIPELINE'
export const GET_RM_PIPELINE='GET_RM_PIPELINE'
export const SET_DEALS='SET_DEALS'
export const SET_CARDS='SET_CARDS'
export const SET_CARD_ORDER='SET_CARD_ORDER'
export const SET_FILTER='SET_FILTER'
export const SET_PROPOSAL='SET_PROPOSAL'
export const SET_SALES_VISIT='SET_SALES_VISIT'
export const GET_DETAIL_DEAL='GET_DETAIL_DEAL'
export const RESET_PROPOSAL='RESET_PROPOSAL'
export const RESET_SALES_VISIT='RESET_SALES_VISIT'
export const SET_TOTAL_PV='SET_TOTAL_PV'
export const SET_TOTAL_PV_PROJECT='SET_TOTAL_PV_PROJECT'
export const SET_PIPELINE='SET_PIPELINE'
export const SET_PROJECTION='SET_PROJECTION'
export const SET_CARDS_PROJECTION='SET_CARDS_PROJECTION'
export const SET_CLIENT_ID='SET_CLIENT_ID'
export const SET_SUMMARY='SET_SUMMARY'
export const GET_LOST_DEAL='GET_LOST_DEAL'
export const SET_LOST_DEAL_PAGINATION='GET_LOST_DEAL'
// export const SET_FILTER_SEGMENT='SET_FILTER_SEGMENT'
// export const SET_FILTER_RM='SET_FILTER_RM'
// export const SET_FILTER_PROBABILITY='SET_FILTER_PROBABILITY'

